<ng-container *ngIf="customer || true">
  <div class="row customer-top">
    <div class="col-sm-5 chat">
      <chat [customer]="customer"></chat>
    </div>
    <div class="col-sm-7">
      <div class="edit-buttons">
        <button type="button" class="btn btn-primary" [hidden]="editing" (click)="edit_start()">編集</button>
        <button type="button" class="btn btn-secondary" [hidden]="!editing" (click)="edit_cancel()">キャンセル</button>
        <button type="button" class="btn btn-primary" [hidden]="!editing" (click)="edit_save()">保存</button>
      </div>
      <div class="table-responsive">
        <table class="table">
          <tr property-row *ngFor="let row of property_rows"
            [id]="row.id"
            [name]="row.name"
            [(ngModel)]="properties[row.id]"
            [type]="row.type"
            [editing]="editing"
            [options]="row.options"
            [placeholder]="row.placeholder"
            [suffix]="row.suffix"
            [prefix]="row.prefix"
            [readonly]="row.readonly"
            [hidden]="!editing && !properties[row.id]"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  
  <data-table 
    id="customers.view.plans"
    title="申請・プラン一覧"
    [rows]="plans"
    [columns]="{
      'state' : { name : 'ステータス・申請日', customCell : stateCell },
      'hope' : { name : 'おもちゃの希望', class : 'hope', customCell : wrapCell },
      'situation' : { name : '最近の様子', class : 'situation', customCell : wrapCell },
      'toys' : { name : 'おもちゃ' , customCell : toysCell },
      'action' : { name : 'アクション', customCell : actionCell }
    }">
  
  </data-table>

  <data-table 
    id="customers.view.reviews"
    title="評価履歴"
    [rows]="reviews"
    [columns]="{
      'toy.*' : { name : 'おもちゃ', customCell : nameCell },
      'plan.state' : { name : '最終ステータス', type : 'select', data : enum_data.plan_toy_status },
      'star' : { name : '評価' },
      'comment' : { name : 'レビュー内容', customCell : wrapCell },
      'plan.start_date' : { name : 'レンタル日', type : 'date' },
      'action' : { name : 'アクション', customCell : reviewActionCell }
    }">
  </data-table>

  <data-table 
    id="customers.view.charges"
    title="スポット課金履歴"
    [rows]="charges"
    [columns]="{
      'type' : { name : '種類', type : 'select', data : enum_data.charge_types },
      'state' : { name : '最終ステータス', type : 'select', data : enum_data.charge_status },
      'amount' : { name : '請求額', customCell : amountCell },
      'message' : { name : 'メッセージ' },
      'createdAt' : { name : '請求日', type : 'datetime' },
      'billedAt' : { name : '支払日', type : 'datetime' }
    }">
  </data-table>

  <data-table 
    id="customers.view.changes"
    title="変更履歴"
    [rows]="changes"
    [columns]="{
      'staff.name' : { name : '担当者' },
      'field' : { name : '項目', type : 'select', data : enum_data.customer_change_fields },
      'before_value' : { name : '変更前', customCell : changeValueCell },
      'after_value' : { name : '変更後', customCell : changeValueCell },
      'createdAt' : { name : '変更日時', type : 'datetime' }
    }">
  </data-table>
</ng-container>

<ng-template #stateCell let-row="row" let-value="value">
  <p class="state">{{enum_data.planStateByValue(row.state).name}}</p>
  <p class="request-date">{{row.request_date | date : "y年M月d日" }}</p>
</ng-template>
<ng-template #toysCell let-row="row" let-value="value">
  <div class="toy" *ngFor="let toy of value">
    <span class="name">{{toy.name}}</span>
    <span class="name">（{{enum_data.planToyStateByValue(toy.state).name}}）</span>
  </div>
</ng-template>
<ng-template #nameCell let-row="row" let-value="value">
  <div class="name-cell">
    <p class="name" [hidden]="!value.name">{{value.name}}</p>
    <p class="name noname" [hidden]="value.name">名前無し</p>
    <div class="tags">
      <p class="genre"[hidden]="!value.detail.genre">{{value.detail.genre}}</p>
      <div *ngFor="let tag of value.detail.tags">
        <p class="tag">{{tag}}</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #actionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/plans/'+row.id">開く</a>
</ng-template>
<ng-template #reviewActionCell let-row="row" let-value="value">
  <div class="btn btn-primary review-toggle" (click)="toggleReviewVisible(row, false)" *ngIf="row.visible">表示中</div>
  <div class="btn btn-primary review-toggle" (click)="toggleReviewVisible(row, true)" *ngIf="!row.visible">非表示中</div>
  <a class="btn btn-primary" [routerLink]="'/toys/'+row.toy.id">開く</a>
</ng-template>
<ng-template #changeValueCell let-row="row" let-value="value">
  <span class="changed-value">{{getChangedValue(row, value)}}</span>
</ng-template>
<ng-template #wrapCell let-row="row" let-value="value">
  <span class="wrap">{{value}}</span>
</ng-template>
<ng-template #amountCell let-row="row" let-value="value">
  <p class="amount">{{(row.amount + row.tax) | currency : 'JPY'}}</p>
</ng-template>
