import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ToysListComponent } from './toys/toys.list/toys.list.component';
import { ToysAddComponent } from './toys/toys.add/toys.add.component';
import { ToysViewComponent } from './toys/toys.view/toys.view.component';
import { CustomersListComponent } from './customers/customers.list/customers.list.component';
import { CustomersViewComponent } from './customers/customers.view/customers.view.component';
import { PlansListComponent } from './plans/plans.list/plans.list.component';
import { PlansViewComponent } from './plans/plans.view/plans.view.component';
import { TasksListComponent } from './tasks/tasks.list/tasks.list.component';
import { PurchasesListComponent } from './purchases/purchases.list/purchases.list.component';
import { CouponsListComponent } from './coupons/coupons.list/coupons.list.component';
import { ChatsListComponent } from './chats/chats.list/chats.list.component';
import { StaffsListComponent } from './staffs/staffs.list/staffs.list.component';
import { StaffsAddComponent } from './staffs/staffs.add/staffs.add.component';
import { StaffsViewComponent } from './staffs/staffs.view/staffs.view.component';
import { SettingsListComponent } from './settings/settings.list/settings.list.component';
import { SettingsSelfComponent } from './settings/settings.self/settings.self.component';
import { BacksListComponent } from './backs/backs.list/backs.list.component';
import { BacksViewComponent } from './backs/backs.view/backs.view.component';
import { ShipsListComponent } from './ships/ships.list/ships.list.component';
import { ShipsSheetComponent } from './ships/ships.sheet/ships.sheet.component';
import { ShipsInvoiceComponent } from './ships/ships.invoice/ships.invoice.component';
import { PurchaseHistoriesListComponent } from './purchase-histories/purchase-histories.list/purchase-histories.list.component';
import { RefundsListComponent } from './refunds/refunds.list/refunds.list.component';
import { ChargesListComponent } from './charges/charges.list/charges.list.component';
import { AdminGuard } from './admin.guard';
import { CardsListComponent } from './cards/cards.list/cards.list.component';
import { CardsViewComponent } from './cards/cards.view/cards.view.component';

export const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate : [AdminGuard,],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component : DashboardComponent,
      },
      {
        path: 'toys',
        data : {
          breadcrumb: 'おもちゃ',
        },
        children : [
          {
            path : "",
            component : ToysListComponent,
          },
          {
            path : "add",
            component : ToysAddComponent,
          },
          {
            path : ":id",
            component : ToysViewComponent,
          },
        ],
      },
      {
        path: 'customers',
        data : {
          breadcrumb: 'ユーザー',
        },
        children : [
          {
            path : "",
            component : CustomersListComponent,
          },
          {
            path : ":id",
            component : CustomersViewComponent,
          },
        ],
      },
      {
        path: 'plans',
        data : {
          breadcrumb: 'プラン',
        },
        children : [
          {
            path : "",
            component : PlansListComponent,
          },
          {
            path : ":id",
            component : PlansViewComponent,
          },
        ],
      },
      {
        path: 'backs',
        data : {
          breadcrumb: '返却',
        },
        children : [
          {
            path : "",
            component : BacksListComponent,
          },
          {
            path : ":id",
            component : BacksViewComponent,
          },
        ],
      },
      {
        path: 'ships',
        data : {
          breadcrumb: '発送',
        },
        children : [
          {
            path : "",
            component : ShipsListComponent,
          },
        ],
      },
      {
        path: 'tasks',
        data : {
          breadcrumb: 'タスク',
        },
        children : [
          {
            path : "",
            component : TasksListComponent,
          },
        ],
      },
      {
        path: 'purchase-histories',
        data : {
          breadcrumb: '支払履歴',
        },
        children : [
          {
            path : "",
            component : PurchaseHistoriesListComponent,
          },
        ],
      },
      {
        path: 'refunds',
        data : {
          breadcrumb: '返金',
        },
        children : [
          {
            path : "",
            component : RefundsListComponent,
          },
        ],
      },
      {
        path: 'charges',
        data : {
          breadcrumb: 'スポット課金',
        },
        children : [
          {
            path : "",
            component : ChargesListComponent,
          },
        ],
      },
      {
        path: 'coupons',
        data : {
          breadcrumb: 'コース',
        },
        children : [
          {
            path : "",
            component : CouponsListComponent,
          },
        ],
      },
      {
        path: 'chats',
        data : {
          breadcrumb: 'チャット',
        },
        children : [
          {
            path : "",
            component : ChatsListComponent,
          },
        ],
      },
      {
        path: 'staffs',
        data : {
          breadcrumb: 'スタッフ',
        },
        children : [
          {
            path : "",
            component : StaffsListComponent,
          },
          {
            path : "add",
            component : StaffsAddComponent,
          },
          {
            path : ":id",
            component : StaffsViewComponent,
          },
        ],
      },
      {
        path: 'settings/list',
        data : {
          breadcrumb: '設定',
        },
        children : [
          {
            path : "",
            component : SettingsListComponent,
          },
        ],
      },
      {
        path: 'settings/self',
        data : {
          breadcrumb: 'セルフコース設定',
        },
        children : [
          {
            path : "",
            component : SettingsSelfComponent,
          },
        ],
      },
      {
        path: 'dashboard',
        component : DashboardComponent,
      },
      {
        path: 'cards',
        data : {
          breadcrumb: 'カード',
        },
        children : [
          {
            path : "",
            component : CardsListComponent,
          },
          // {
          //   path : "add",
          //   component : StaffsAddComponent,
          // },
          {
            path : ":id",
            component : CardsViewComponent,
          },
        ],
      },
      {
        path: '',
        redirectTo: 'sample-page',
        pathMatch: 'full'
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
    ]
  },
  {
    path : "ships/:id/sheet",
    component : ShipsSheetComponent,
    canActivate : [AdminGuard,],
  },
  {
    path : "ships/:id/invoice",
    component : ShipsInvoiceComponent,
    canActivate : [AdminGuard,],
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

