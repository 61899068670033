const Self = require("self");
const moment = require("moment-timezone");

let enum_data;
enum_data = {

  toy_change_fields : [
    { id : 'identifier', value : 'identifier', name :"ID", },
    { id : 'name', value : 'name', name :"名前", },
    { id : 'detail.maker', value : 'detail.maker', name :"メーカー", },
    { id : 'detail.genre', value : 'detail.genre', name :"カテゴリー", },
    { id : 'tags', value : 'tags', name :"タグ", type : "multiselect", data_name : "toy_tags", },
    { id : 'detail.target_age.min', value : 'detail.target_age.min', name :"対象年齢の下限", type : "select", data_name : "ages", },
    { id : 'detail.target_age.max', value : 'detail.target_age.max', name :"対象年齢の上限", type : "select", data_name : "ages", },
    { id : 'detail.price', value : 'detail.price', name :"定価", },
    { id : 'detail.discount_rate', value : 'detail.discount_rate', name :"買取割引率", },
    { id : 'real_stock', value : 'real_stock', name :"実在庫", },
    { id : 'detail.description', value : 'detail.description', name :"説明文", },
    { id : 'detail.parts', value : 'detail.parts', name :"パーツ詳細", },
    { id : 'detail.memos', value : 'detail.memos', name :"メモ", type : "memos", },
  ],

  customer_change_fields : [
    { id : 'identifier', value : 'identifier', name :"ID", },
    { id : 'state', value : 'state', name :"状態", data_name : "customer_status", },
    { id : 'staff_id', value : 'staff_id', name :"担当プランナー", },
    { id : 'name', value : 'name', name :"名前", },
    { id : 'email', value : 'email', name :"メールアドレス", },
    { id : 'detail.child_1', value : 'detail.child_1', name :"子供１", type : "child", },
    { id : 'detail.child_2', value : 'detail.child_2', name :"子供２", type : "child", },
    { id : 'detail.child_3', value : 'detail.child_3', name :"子供３", type : "child", },
    { id : 'detail.child_4', value : 'detail.child_4', name :"子供４", type : "child", },
    { id : 'detail.child_5', value : 'detail.child_5', name :"子供５", type : "child", },
    { id : 'detail.genres', value : 'detail.genres', name :"カテゴリー", data_name : "genres", },
    { id : "shipping_name", value : "shipping_name", name : "郵送先お名前", },
    { id : "shipping_tel", value : "shipping_tel", name : "郵送先電話番号", },
    { id : 'detail.shipping_zipcode', value : 'detail.shipping_zipcode', name :"郵送先郵便番号", },
    { id : 'detail.shipping_address1', value : 'detail.shipping_address1', name :"郵送先住所（番地まで）", },
    { id : 'detail.shipping_address2', value : 'detail.shipping_address2', name :"郵送先住所（マンション名・部屋番号等）", },
    { id : 'detail.tags', value : 'detail.tags', name :"タグ", type : "multiselect", data_name : "customer_tags", },
    { id : 'detail.memos', value : 'detail.memos', name :"メモ", type : "memos", },
  ],

  plan_change_fields : [
    { id : 'state', value : 'state', name :"状態", data_name : "plan_status", },
    { id : 'hope', value : 'hope', name :"おもちゃの要望", },
    { id : 'situation', value : 'situation', name :"最近の様子", },
    { id : 'property', value : 'property', name :"手持ちのおもちゃ", },
    { id : 'other', value : 'other', name :"その他伝えたいこと", },
    { id : 'exclusive', value : 'exclusive', name :"専有タスク", },
    { id : 'battery_type', value : 'battery_type', name :"電池タイプ", },
    { id : 'battery_num', value : 'battery_num', name :"電池個数", },
    { id : 'allocation', value : 'allocation', name :"兄弟配分", },
  ],

  staff_change_fields : [
    { id : 'name', value : 'name', name :"名前",},
    { id : 'email', value : 'email', name :"メールアドレス", },
    { id : 'password_hash', value : 'password_hash', name :"パスワード（暗号化済み）", skip : true, },
    { id : "grant", value : "grant", name : "権限", type : "select", data_name : "staff_grants", },
  ],

  coupon_change_fields : [
    { id : 'order', value : 'order', name :"順番", },
    { id : 'name', value : 'name', name :"割引コード名", },
    { id : 'course_name', value : 'course_name', name :"コース名", },
    { id : 'enable', value : 'enable', name :"有効/無効", type : "select", data_name : "coupon_enables", },
    { id : 'code', value : 'code', name :"割引コード", },
    { id : 'months', value : 'months', name :"決済間隔（月数）", },
    { id : 'price', value : 'price', name :"月額基本料金", },
    { id : 'total_amount', value : 'total_amount', name :"コース料金", },
    { id : "product_id", value : "product_id", name : "RP商品コード", },
    { id : "type", value : "type", name : "種別", },
  ],

  sexes : [
    "男",
    "女",
    "未回答",
  ],

  staff_grants : [
    { id : 'admin', value : 'admin', name :"管理者", },
    { id : 'office', value : 'office', name :"事務チーム", },
    { id : 'plan', value : 'plan', name :"プランナー", },
    { id : 'ship', value : 'ship', name :"発送チーム", },
    { id : 'back', value : 'back', name :"返却確認チーム", },
  ],

  ages : [
    { id : "3", value : "3", name : "３ヶ月", },
    { id : "4", value : "4", name : "４ヶ月", },
    { id : "5", value : "5", name : "５ヶ月", },
    { id : "6", value : "6", name : "６ヶ月", },
    { id : "7", value : "7", name : "７ヶ月", },
    { id : "8", value : "8", name : "８ヶ月", },
    { id : "9", value : "9", name : "９ヶ月", },
    { id : "10", value : "10", name : "１０ヶ月", },
    { id : "11", value : "11", name : "１１ヶ月", },
    { id : "12", value : "12", name : "１歳", },
    { id : "14", value : "14", name : "１歳２ヶ月", },
    { id : "16", value : "16", name : "１歳４ヶ月", },
    { id : "18", value : "18", name : "１歳６ヶ月", },
    { id : "20", value : "20", name : "１歳８ヶ月", },
    { id : "22", value : "22", name : "１歳１０ヶ月", },
    { id : "24", value : "24", name : "２歳", },
    { id : "26", value : "26", name : "２歳２ヶ月", },
    { id : "28", value : "28", name : "２歳４ヶ月", },
    { id : "30", value : "30", name : "２歳６ヶ月", },
    { id : "36", value : "36", name : "３歳", },
    { id : "48", value : "48", name : "４歳", },
    { id : "60", value : "60", name : "５歳", },
    { id : "72", value : "72", name : "６歳", },
  ],

  customer_status : [
    { id : 'unregister', value : 'unregister', name : "メール認証済み", },
    { id : 'payed', value : 'payed', name : "決済登録済み", },
    { id : 'new', value : 'new', name : "登録待ち", },
    { id : 'active', value : 'active', name : "利用中", },
    { id : 'leaving', value : 'leaving', name : "退会申請済み", },
    { id : 'leaved', value : 'leaved', name : "退会済み", },
  ],

  customer_tags : [
    { id : "1", value : "1", name : "クレーム歴有り", icon : "", },
    { id : "2", value : "2", name : "モンテッソーリ", icon : "", },
    { id : "3", value : "3", name : "インシデントあり", icon : ""},
    { id : "4", value : "4", name : "御協力候補者", icon : ""},
  ],

  genders : [
    { id : "1", value : "1", name : "男子", },
    { id : "2", value : "2", name : "女子", },
  ],

  toy_labels : [
    { id : "history", value : "history", name : "レンタル歴有り", },
    { id : "pending", value : "pending", name : "提案中", },
    { id : "fixed", value : "fixed", name : "確定", },
    { id : "rejected", value : "rejected", name : "却下", },
    { id : "extend", value : "extend", name : "延長", },
  ],

  memo_types : [
    { id : "normal", value : "normal", name : "通常", },
    { id : "plan", value : "plan", name : "プランナーメモ", },
    { id : "ship", value : "ship", name : "発送メモ", },
    { id : "back", value : "back", name : "返却メモ", },
  ],

  plan_status : [
    { id : 'request', value : 'request', name : "提案待ち", },
    { id : 'planning', value : 'planning', name : "提案中", },
    { id : 'shipping', value : 'shipping', name : "発送中", },
    { id : 'rental', value : 'rental', name : "レンタル中", },
    { id : 'backing', value : 'backing', name : "返却中", },
    { id : 'backed', value : 'backed', name : "返却済み", },
    { id : 'lost', value : 'lost', name : "パーツ不足", },
  ],

  plan_is_firsts : [
    { id : '0', value : '0', name : "交換申請", },
    { id : '1', value : '1', name : "初回申請", },
  ],

  plan_toy_status : [
    { id : 'unfix', value : 'unfix', name : "未確定", },
    { id : 'fix', value : 'fix', name : "確定", },
    { id : "rejected", value : "rejected", name : "却下", },
    { id : 'prepare', value : 'prepare', name : "発送準備中", },
    { id : 'rental', value : 'rental', name : "レンタル中", },
    { id : 'back', value : 'back', name : "返却予定", },
    { id : 'backing', value : 'backing', name : "返却中", },
    { id : 'backed', value : 'backed', name : "返却済み", },
    { id : "extending", value : "extending", name : "延長予定", },
    { id : "extend", value : "extend", name : "延長", },
    { id : "extended", value : "extended", name : "延長済み", },
    { id : 'buy_request', value : 'buy_request', name : "購入希望", },
    { id : 'bought', value : 'bought', name : "購入済み", },
    { id : 'lost', value : 'lost', name : "紛失", },
    { id : 'partless', value : 'partless', name : "パーツ不足", },
  ],

  plan_battery_types : [
    { id : '3', value : '3', name : "単三電池", },
    { id : '2', value : '2', name : "単二電池", },
    { id : '4', value : '4', name : "単四電池", },
    { id : 'button', value : 'button', name : "ボタン電池", },
    { id : 'other', value : 'other', name : "その他", },
  ],

  coupon_enables : [
    { id : '0', value : '0', name : "無効", },
    { id : '1', value : '1', name : "有効", },
    { id : '2', value : '2', name : "一回限り有効", },
  ],

  coupon_types : [
    { id : 'standard', value : 'standard', name : "スタンダード", },
    { id : 'premium', value : 'premium', name : "プレミアム", },
    { id : 'self', value : 'self', name : "セルフ", },
  ],

  purchase_history_status : [
    { id : 'succeeded', value : 'succeeded', name : "成功", },
    { id : 'failed', value : 'failed', name : "失敗", },
  ],

  task_types : [
    { id : "new_user", value : "new_user", name : "新規ユーザー登録", },
    { id : "planning", value : "planning", name : "プラン作成", },
    { id : "shipping", value : "shipping", name : "発送", },
    { id : "talk", value : "talk", name : "チャット", },
    { id : "change_address", value : "change_address", name : "登録情報変更", },
    { id : "change_course", value : "change_course", name : "コース変更", },
    { id : "purchase_failed", value : "purchase_failed", name : "課金失敗", },
    { id : "backing", value : "backing", name : "返却確認", },
    { id : "partless", value : "partless", name : "パーツ不足", },
    { id : "charge_billed", value : "charge_billed", name : "支払い完了", },
    { id : "toy_buy", value : "toy_buy", name : "おもちゃ購入", },
    { id : "leave", value : "leave", name : "退会処理", },
  ],

  task_status : [
    { id : 'new', value : 'new', name : "新規", },
    { id : 'checked', value : 'checked', name : "発生中", },
    { id : 'finish', value : 'finish', name : "完了", },
    { id : 'delete', value : 'delete', name : "削除", },
  ],

  talk_status : [
    { id : 'sent', value : 'sent', name : "送信済み", },
    { id : 'schedule', value : 'schedule', name : "予約済み", },
    { id : 'error', value : 'error', name : "送信失敗", },
    { id : 'cancel', value : 'cancel', name : "キャンセル", },
  ],

  charge_status : [
    { id : 'request', value : 'request', name : "請求中", },
    { id : 'billing', value : 'billing', name : "支払い確認中", },
    { id : 'billed', value : 'billed', name : "支払い完了", },
  ],

  charge_types : [
    { id : 'planner', value : 'planner', name : "プランナー作成", },
    { id : 'buy', value : 'buy', name : "買取", },
  ],

  chat_unchecks_unreads : [
    { id : '0', value : '0', name : "既読", },
    { id : '1', value : '1', name : "未読", },
  ],

  chat_unchecks_unreplys : [
    { id : '0', value : '0', name : "返信済み", },
    { id : '1', value : '1', name : "未返信", },
  ],

  links : [
    { id : false, value : false, name : "未連携", },
    { id : true, value : true, name : "連携済み", },
  ],

  cards : [
    { id : "1", value : "1", name : "個人情報の入力ができていない人", },
    { id : "2", value : "2", name : "個人情報登録後にまだ決済情報の登録ができていない人", },
    { id : "3", value : "3", name : "退会した人", },
  ],

  card_btn_types : [
    { id : "", value : "", name :"なし", },
    { id : "1", value : "1", name :"ＨＰの記事へ", },
    { id : "2", value : "2", name :"途中だった決済手続きへ", },
    { id : "3", value : "3", name :"テキストで自動返信", },
  ],

  card_infos : [
    { id : "1", value : "1", name :"申込みに進む", url: "/line/edit"},
    { id : "2", value : "2", name :"決済登録に進む", url: "/line/checkout" },
    { id : "3", value : "3", name :"再申込に進む", url: "/line/register" },
  ],

  toy_selfs : [
    { id : "no", value : "no", name : "非対象", },
    { id : "yes", value : "yes", name : "対象", },
  ],

  toWideCharacterNumber : function (self, string) {
    return string
      .replace(/0/g, "０")
      .replace(/1/g, "１")
      .replace(/2/g, "２")
      .replace(/3/g, "３")
      .replace(/4/g, "４")
      .replace(/5/g, "５")
      .replace(/6/g, "６")
      .replace(/7/g, "７")
      .replace(/8/g, "８")
      .replace(/9/g, "９")
  },

  getAgeString : function (self, months) {
    if (!months && months !== "0" && months !== 0) {
      return "";
    }
    let age = Math.floor(Number(months) / 12);
    let month = months - age * 12;
    if (age > 0) {
      return self.toWideCharacterNumber(`${age}歳${month}ヶ月`);
    } else {
      return self.toWideCharacterNumber(`${month}ヶ月`);
    }
  },

  getAge : function (self, value) {
    let months = self.getAgeRaw(value);
    return self.getAgeString(months);
  },

  getAgeRaw : function (self, value) {
    let months = moment().diff(value, 'months');
    return months;
  },

  appendData : function (self, name, data) {
    self[name] = data;
    self.convert(name);
  },

  convert : function (self, key) {
    if ((typeof self[key]) != "object") {
      return;
    }
    let func_prefix = key
      .replace("status", "state")
      .replace(/_./g, (s) => { return s.charAt(1).toUpperCase(); })
      .replace(/s$/, "");

    if (typeof (self[key] || [])[0] == "string") {
      self[key] = self[key].map((data) => { return { id : data, value : data, name : data, }; });
    }
    self[key] = self[key].map((data) => { data.display = data.name; data.label = data.name; return data; });

    self[key + "_notdeleted"] = self[key].filter((data) => { return !data.deleted; });

    self[func_prefix + "ById"] = self[func_prefix + "ById"] || ((id) => {
      return self[key].filter((type) => { return type.id == id; } ).shift() || {};
    });
    self[func_prefix + "ByValue"] = self[func_prefix + "ByValue"] || ((value) => {
      return self[key].filter((type) => { return type.value == value; } ).shift() || {};
    });
    self[func_prefix + "ByName"] = self[func_prefix + "ByName"] || ((name) => {
      return self[key].filter((type) => { return type.name == name; } ).shift() || {};
    });
  },

  convertAll : function (self) {
    Object.keys(enum_data).forEach((key) => {
      self.convert(key);
    });
  },

};

module.exports = new Self(enum_data)();
module.exports.convertAll();
