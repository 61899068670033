import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './services/api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private api : ApiService, private router : Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.api.auth.getMe().toPromise().then((me) => {
      if (!me) {
        throw null;
      }
      return this.api.prepare().toPromise();
    }).then(() => {
      return true;
    }).catch(error => {
      this.router.navigate(["/auth/signin"]);
      return false;
    })
  }
  
}
